import * as THREE from 'three'
import GUI from 'lil-gui'
import { EffectComposer, GLTFLoader, RenderPass, UnrealBloomPass } from 'three/examples/jsm/Addons.js'
// import { OrbitControls } from 'three/examples/jsm/Addons.js'
import gsap from 'gsap'

document.addEventListener("DOMContentLoaded", function() {
    if(document.documentElement.scrollTop !== 0) {
        document.getElementById("splash-screen").style.display = "none";
        return;
    }
    document.documentElement.classList.add('no-scroll');
    document.getElementById("splash-screen").style.opacity = '0.8';
    setTimeout(function() {
        document.getElementById("splash-screen").style.opacity = '0';
        
        setTimeout(function() {
            document.documentElement.classList.remove('no-scroll');
            document.getElementById("splash-screen").style.display = 'none';          
        }, 1000); 
    }, 3000);
});

let isLinkScroll = false;
/**
 * Scrolling smoothly logic
 */
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function(e) {
        e.preventDefault();
        isLinkScroll = true;

        const target = document.querySelector(this.getAttribute('href'));
        target.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });

        setTimeout(() => {
            isLinkScroll = false;
        }, 1000);
    });
});

/**
 * Header Logic
 */
function wrapLetters(selector) {
    document.querySelectorAll(selector).forEach(element => {
        const text = element.innerText;
        element.innerHTML = ''; 
        text.split('').forEach((letter, index) => {
            const span = document.createElement('span');
            span.innerText = letter === ' ' ? '\u00A0' : letter; 
            span.style.setProperty('--i', index); 
            element.appendChild(span);
        });
    });
}

wrapLetters('.swe');
wrapLetters('.cd');


/**
 * Texture Loader
 */
const textureLoader = new THREE.TextureLoader();

// main textures
const kaisaTexture = textureLoader.load('textures/models/kaisa.png');
const teemoTexture = textureLoader.load('textures/models/teemo.png');
const celloBenchTexture = textureLoader.load('textures/models/celloBench.png');

kaisaTexture.colorSpace = THREE.SRGBColorSpace;
kaisaTexture.magFilter = THREE.NearestFilter;
kaisaTexture.minFilter = THREE.NearestFilter;
kaisaTexture.generateMipmaps = false;

teemoTexture.colorSpace = THREE.SRGBColorSpace;
teemoTexture.magFilter = THREE.NearestFilter;
teemoTexture.minFilter = THREE.NearestFilter;
teemoTexture.generateMipmaps = false;

kaisaTexture.flipY = false;
teemoTexture.flipY = false;

celloBenchTexture.colorSpace = THREE.SRGBColorSpace;
celloBenchTexture.magFilter = THREE.NearestFilter;
celloBenchTexture.minFilter = THREE.NearestFilter;
celloBenchTexture.generateMipmaps = false;
celloBenchTexture.flipY = false;

/**
 * Debug
 */
const gui = new GUI();
gui.hide();

const parameters = {
    materialColor: '#ffeded'
};

gui.addColor(parameters, 'materialColor');

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();

// Light
const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 1);
hemiLight.position.set(0, 20, 0);
scene.add(hemiLight);

const ambientLight = new THREE.AmbientLight(0xFF4CFB, 1.25); 
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xFFFA85, 1.55);
directionalLight.position.set(-20, 60, 80);
directionalLight.castShadow = true;
directionalLight.shadow.mapSize.width = 1024 * 2;
directionalLight.shadow.mapSize.height = 1024 * 2;
directionalLight.shadow.camera.left = -100;
directionalLight.shadow.camera.right = 100;
directionalLight.shadow.camera.top = 100;
directionalLight.shadow.camera.bottom = -100;

directionalLight.shadow.camera.near = 0.5;
directionalLight.shadow.camera.far = 200;

scene.add(directionalLight);



/***
 * Materials
 */
const floorMaterial = new THREE.MeshStandardMaterial({
    color: 'white',
    emissive: new THREE.Color('#FF4CFB'),
    emissiveIntensity: 0.2,
});

const kaisaMaterial = new THREE.MeshStandardMaterial({
    map: kaisaTexture,
    transparent: true,
    alphaTest: 0.5,
    roughness: 0.8,
    metalness: 0.0
});

const teemoMaterial = new THREE.MeshStandardMaterial({
    map: teemoTexture,
    transparent: true,
    alphaTest: 0.5,
    roughness: 0.8,
    metalness: 0.0
});

const celloBenchMaterial = new THREE.MeshStandardMaterial({
    map: celloBenchTexture,
    transparent: true,
    alphaTest: 0.5,
    roughness: 0.8,
    metalness: 0.0
});

/***
 * Models
 */
// Loading the model
const gltfLoader = new GLTFLoader();

// main scenes
gltfLoader.load('models/TeemoKaisa/KaisaTeemo.gltf',
    (gltf) => {{
        const kaisa = gltf.scene.children[0];
        const teemo = gltf.scene.children[1];
        const floor = gltf.scene.children[2];

        floor.traverse((child) => {
            child.material = floorMaterial;
            child.receiveShadow = true;
        });

        kaisa.traverse((child) => {
            if(child.isMesh){
                child.material = kaisaMaterial;
                child.castShadow = true;
                child.receiveShadow = true;
                child.geometry.computeBoundingBox();
                child.geometry.computeBoundingSphere();
            }
        });


        teemo.traverse((child) => {
            child.material = teemoMaterial;
            child.castShadow = true;
            child.receiveShadow = true;
        });
    

        teemo.scale.set(9.5,9.5,9.5);
        const teemoPos = teemo.position;
        teemoPos.y += 3;
        teemoPos.x -= 2;
        teemo.position.copy(teemoPos);

        teemo.rotateOnAxis(new THREE.Vector3(0,1,0), Math.PI/32);
        teemo.rotateOnAxis(new THREE.Vector3(1,0,0), Math.PI/128);

        scene.add(gltf.scene);
    }}
);

// Cello
gltfLoader.load('models/CelloBench/CelloBench.gltf', 
    (gltf) => {
        const scale = 10;
        const model = gltf.scene;
        model.scale.set(scale, scale, scale);
        model.position.set(-80, 0 , 20)
        model.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI/180 * (-60));
        model.traverse((child) => {
            child.material = celloBenchMaterial;
            child.castShadow = true;
            child.receiveShadow = true;
        })
        scene.add(gltf.scene);
});

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Update post processing
    composer.setSize(sizes.width, sizes.height);
    bloomPass.resolution.set(sizes.width, sizes.height);
    
})

/**
 * Camera
 */

// Camera Positions
const cameraPositions = {};

cameraPositions.home = {
    pos: {x: 0, y: 18, z: 50},
    lookAt: new THREE.Vector3(0, 10, 0),
    onEnter: () => {},
    onExit: () => {}
};

cameraPositions.aboutMe = {
    pos: {x: 5, y: 15, z: 22},
    lookAt: new THREE.Vector3(-26, 10, 0),
    onEnter: () => {
        document.getElementById('about-container').classList.remove('fade-exit');
        document.getElementById('about-container').classList.add('fade-enter');

    },
    onExit: () => {
        // document.getElementById('about-container').classList.remove('fade-enter');
        // document.getElementById('about-container').classList.add('fade-exit');
    }
};

cameraPositions.projects = {
    pos: {x: -40, y: 20, z: 35.5},
    lookAt: new THREE.Vector3(-65, 10, 0),
    onEnter: () => {
        document.getElementById('projects').scrollTo();
        if(!isLinkScroll){
            document.getElementById('project-container').scrollTop = 0;
        }
        document.getElementById('project-container').classList.add('scrollable');
        document.getElementById('project-container').classList.remove('fade-exit');
        document.getElementById('project-container').classList.add('fade-enter');

        const cards = document.querySelectorAll('.project-card');
        const newClass = 'project-card-anim';

        cards.forEach((card, index) => {
        setTimeout(() => {
            card.classList.add(newClass);
        }, index * 100); 
        });

    },
    onExit: () => {
        if(!isLinkScroll){
            document.getElementById('project-container').scrollTop = 0;
        }
        // document.getElementById('project-container').classList.remove('scrollable');
        // document.getElementById('project-container').classList.remove('fade-enter');
        // document.getElementById('project-container').classList.add('fade-exit');

        // const cards = document.querySelectorAll('.project-card');
        // const newClass = 'project-card-anim';

        // cards.forEach((card, index) => {
        // setTimeout(() => {
        //     card.classList.remove(newClass);
        // }, index); 
        // });
    }
};

cameraPositions.contact = {
    pos: {x: -40, y: 20, z: -20.0},
    lookAt: new THREE.Vector3(-65, 10, -80),
    onEnter: () => {
        document.getElementById('contact-container').classList.remove('fade-exit');
        document.getElementById('contact-container').classList.add('fade-enter');
    },
    onExit: () => {
        
    }
};

// Base camera
const camera = new THREE.PerspectiveCamera(65, sizes.width / sizes.height, 0.1, 1000);
camera.position.z = 50;
camera.position.y = 18;
camera.lookAt(cameraPositions.home.lookAt);

scene.add(camera);

// Camera group
const cameraGroup = new THREE.Group();
scene.add(cameraGroup);
cameraGroup.add(camera);

function lerp(start, end, alpha) {
    return (1 - alpha) * start + alpha * end;
}

const cameraSections = [cameraPositions.home, cameraPositions.aboutMe, cameraPositions.projects, cameraPositions.contact];
const numSections = cameraSections.length - 1;
let buffer = 0.1;
// const numSections = 0;

const onEnterSection = (index) => {
    cameraSections[index].onEnter();
};

const onExitSection = (index) => {
    cameraSections[index].onExit();
};

let currentIndex = 0;
function updateCameraWithScroll(scrollPercentage, override) {

    if(override) {
        buffer = 0;
    }

    // Calculate Section
    let sectionIndex = Math.floor(scrollPercentage * numSections + 0.05);
    const sectionScrollStart = sectionIndex / numSections;
    const sectionScrollEnd = (sectionIndex + 1) / numSections;

    if(currentIndex != sectionIndex) {
        onExitSection(currentIndex);
        currentIndex = sectionIndex;
        onEnterSection(currentIndex);
    }

    // sectionIndex = (sectionIndex >= cameraSections.length-1) ? sectionIndex-1 : sectionIndex;
    // console.log(sectionIndex)
    let sectionScrollPercentage = (scrollPercentage - sectionScrollStart) / (sectionScrollEnd - sectionScrollStart);
    
    // if(sectionIndex == cameraSections.length-2 && sectionScrollPercentage <= 0.5) {
    //     sectionScrollPercentage = 0.9;
    // }

    const bufferStart = buffer; 
    const bufferEnd = 1 - buffer;

    if (sectionScrollPercentage > bufferStart && sectionScrollPercentage < bufferEnd) {
        const adjustedPercentage = (sectionScrollPercentage - bufferStart) / (bufferEnd - bufferStart);

        const startPos = cameraSections[sectionIndex].pos;
        const endPos = cameraSections[sectionIndex + 1].pos;
        const startLookAt = cameraSections[sectionIndex].lookAt;
        const endLookAt = cameraSections[sectionIndex + 1].lookAt;

        const posX = lerp(startPos.x, endPos.x, adjustedPercentage);
        const posY = lerp(startPos.y, endPos.y, adjustedPercentage);
        const posZ = lerp(startPos.z, endPos.z, adjustedPercentage);

        const lookAtX = lerp(startLookAt.x, endLookAt.x, adjustedPercentage);
        const lookAtY = lerp(startLookAt.y, endLookAt.y, adjustedPercentage);
        const lookAtZ = lerp(startLookAt.z, endLookAt.z, adjustedPercentage);

        gsap.to(camera.position, {
            x: posX,
            y: posY,
            z: posZ,
            duration: 1,
            ease: 'power2.out'
        });

        gsap.to(camera, {
            onUpdate: () => {
                camera.lookAt(new THREE.Vector3(lookAtX, lookAtY, lookAtZ));
            },
            duration: 1,
            ease: 'power1.out'
        });
    }
}

// const controls = new OrbitControls(camera, canvas);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.toneMapping = THREE.AgXToneMapping;
renderer.toneMappingExposure = 1;

/**
 * Post Processing
 */
const composer = new EffectComposer(renderer);

// Render Pass
const renderPass = new RenderPass( scene, camera );
composer.addPass( renderPass );

// Bloom Pass
const bloomPassParams = {};
bloomPassParams.threshold = 0.36;
bloomPassParams.strength = 0.15;
bloomPassParams.radius = 0.25;

const bloomPassGUI = gui.addFolder('bloom pass');
bloomPassGUI.add(bloomPassParams, 'threshold')
    .min(0)
    .max(1)
    .step(0.01)
    .onChange(() => {
        composer.removePass(bloomPass);
        bloomPass.threshold = bloomPassParams.threshold;
        composer.addPass(bloomPass);
});

bloomPassGUI.add(bloomPassParams, 'strength')
    .min(0)
    .max(3)
    .step(0.01)
    .onChange(() => {
        composer.removePass(bloomPass);
        bloomPass.strength = bloomPassParams.strength;
        composer.addPass(bloomPass);
});;

bloomPassGUI.add(bloomPassParams, 'radius')
    .min(0)
    .max(1)
    .step(0.01)
    .onChange(() => {
        composer.removePass(bloomPass);
        bloomPass.radius = bloomPassParams.radius;
        composer.addPass(bloomPass);
});;

const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth,window.innerWidth), bloomPassParams.strength, bloomPassParams.radius, bloomPassParams.threshold);
composer.addPass(bloomPass);

/**
 * Scroll 
 */

const scrollEvent = (override) => {
    const scrollTop = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercentage = scrollTop / documentHeight;

    updateCameraWithScroll(scrollPercentage, override);
};

scrollEvent(true);

window.addEventListener('scroll', () => {
    scrollEvent(false);
});

/**
 * Cursor
 */
const cursor = {};
cursor.x = 0;
cursor.y = 0;

window.addEventListener('mousemove', (event) => {
    cursor.x = event.clientX / sizes.width - 0.5;
    cursor.y = event.clientY / sizes.height - 0.5;
})

/**
 * Tab Event
 */
let isTabActive = true;

document.addEventListener('visibilitychange', () => {
    isTabActive = !document.hidden;
});

/**
 * Animate
 */
const clock = new THREE.Clock();
let previousTime = 0;

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

    // Animate Camera
    if(isTabActive){
        const parallaxX = cursor.x * 0.5;
        const parallaxY = - cursor.y * 0.5;
        cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime;
        cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime;
    }
    // console.log(camera.position)

    // Render
    // renderer.render(scene, camera)

    // // Apply Post Processing
    composer.render();

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
}

function adjustCanvasSize() {
    const canvas = document.querySelector('canvas');
    const viewportHeight = window.innerHeight;

    // Set the canvas height to the current viewport height
    canvas.style.height = `${viewportHeight}px`;

    // Also update the renderer if needed
    renderer.setSize(window.innerWidth, viewportHeight);
}

// Call this function on load and resize
window.addEventListener('resize', adjustCanvasSize);
window.addEventListener('orientationchange', adjustCanvasSize);
adjustCanvasSize();  // Initial call when the page loads

tick()